import {
    GetFields
} from "../../../factories/staticListAnnotatorsFactory";
import moment from "moment";
export const ListAnalyticsAnnotatorMixin = {
    filters: {

    },
    props: {
        type: [String],
        title: [String],
        rows: [Array],
        numberpages: [Number],

    },
    data() {
        return {
            listFields: [],
            paginated: '',
            pages: [],
            typeField: {},
            paginationType: "fast",
            pageLongIndex: 1,
            pageIndex: 1,
            totalPages: 0,
            searchPage: ''
        }
    },
    created() {
        moment.locale('pt-br')
    },
    mounted() {

        this.getFields()
    },
    computed: {
        duplicate_linha: function() {
            return this.$duplicate(this.rows)
        }
    },
    watch: {
        rows() {

            this.getFields()
        },
        updated() {

        }
    },
    methods: {
        paginate() {
            this.paginated = this.typeField.paginateFields(
                this.$duplicate(this.typeField.fieldsList),
                this.pageLongIndex,
                this.numberpages
            );
            this.$nextTick(() => {
                    this.mountPages(this.paginated.total_pages);
                })
                //console.log(this.paginated)
        },
        changePage(index) {
            this.pageLongIndex = index
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );
            this.pages = this.pages.map((val) => {
                if (val.page === index) val.status = "active";
                else val.status = "inactive";
                return val;
            });

        },
        mountPages(totalPages) {
            this.pages = [];
            this.totalPages = totalPages;
            if (totalPages < 15) {
                this.paginationType = "fast";
                for (let i = 1; i <= totalPages; i++) {
                    this.pages.push({
                        page: i,
                        status: "inactive",
                    });
                    this.pages[0].status = "active";
                }
            } else {
                this.paginationType = "long";
                this.pages.push({
                    page: "Next",
                    status: "next",
                });
                this.pages.unshift({
                    page: "Previous",
                    status: "previous",
                });
            }
        },
        previousPage() {
            if (this.pageLongIndex === 1) {
                this.pageLongIndex = this.totalPages;
            } else {
                this.pageLongIndex = this.pageLongIndex - 1;
            }
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );

        },
        nextPage() {
            if (this.pageLongIndex === this.totalPages) {
                this.pageLongIndex = 1;
            } else {
                this.pageLongIndex = this.pageLongIndex + 1;
            }
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );

        },
        inputChange(index) {
            if (index > this.totalPages) {
                this.pageLongIndex = this.totalPages
            } else if (index < 1) {
                this.pageLongIndex = 1
            } else {
                this.pageLongIndex = index
            }
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );

        },
        getFields() {
            this.listFields = [];
            this.listFields = this.duplicate_linha;
            this.typeField = new Object();
            this.typeField = new GetFields(this.listFields);
            this.paginate();

        },
    },

}
<template>
    <div class="container fieldList">
      <template v-if="paginated !== ''">

      <h2>{{ title }}</h2>
      <table class="table table-striped">
        <thead>
           <tr>
            
            <th>Nome</th>
            <td>Tempo trabalhado**</td>
            <td>Anotações</td>
            <td>Corretas</td>
            <td>Incorretas</td>
            <td>Finalizadas</td>
            <td>Accuracy%</td>
            
            

          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            
                <td>{{list.name}}</td>
                <td>{{list.timehour}}</td>
                <td>{{list.count}}</td>
                <td>{{list.correct}}</td>
                <td>{{list.incorrect}}</td>
                <td>{{list.finished}}</td>
                <td>{{list.accuracy}}</td>

                
          
          </tr>
        </tbody>
      </table>


       <div class="row" v-if="paginationType === 'fast'">
        <div class="pagination">
          <a
            v-for="(page, key) in pages"
            :key="key"
            :class="`${page.status}`"
            @click="changePage(page.page)"
            >{{ page.page }}</a
          >
        </div>
      </div>
      <div class="row pages" v-if="paginationType === 'long'">
        <div class="col-md-2">
          <div class="pagination">
            <a :class="`${pages[0].status}`" @click="previousPage()">{{
              pages[0].page
            }}</a>
            <a :class="`${pages[1].status}`" @click="nextPage()">{{
              pages[1].page
            }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <h3>{{ pageLongIndex }} de {{ totalPages }} páginas</h3>
        </div>
        <div class="col-md-4">
          <input
            class="form-group"
            type="text"
            v-model="searchPage"
            placeholder="Procurar página..."
            @change="inputChange(searchPage)"
          />
        </div>
      </div>
      
      </template>
    </div>

</template>

<script type="text/javascript">
import { ListAnalyticsAnnotatorMixin } from "./list-analytics-annotators.mixin";
export default {
  methods: {},
  name: "ListAnnotators",
  mixins: [ListAnalyticsAnnotatorMixin],
};
</script>

<style src="./list-analytics-annotators.less" lang="less" />

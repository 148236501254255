import { Apiannotator } from '@/axios'

export const getAnnotations = (type = "ner_fix") => {
    return Apiannotator.getAll(`get_annotations/${type}`)
}

export const getAnnotationsglobal = (type = "ner_fix") => {
    return Apiannotator.getAll(`get_annotations_global/${type}`)
}

export const getAnnotationsnotfinished = (type = "ner_fix") => {
    return Apiannotator.getAll(`get_annotations_by_paragraph/${type}`)
}

export const getAnnotationsbyid = (data, type = "ner_fix") => {
    return Apiannotator.post(`get_annotations_by_paragraph${type}`, data)
}


export const getAnnotationsbyDate = (data, type = "ner_fix") => {
    return Apiannotator.post(`get_annotations/${type}`, data)
}

export const sendNotverified = (data, type = "ner_fix") => {
    return Apiannotator.postVerify(`${type}/check`, data)
}

export const postVerified = (data, type = "ner_fix") => {
    return Apiannotator.postVerify(`${type}/verify`, data)
}

export const updateAnnotation = (data, type = "ner_fix") => {
    return Apiannotator.postVerify(`${type}/update`, data)
}
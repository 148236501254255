import moment from "moment";

export class Baselist {
    constructor() {
        this.fieldsList = []
    }
    createFields(fields) {
        this.fieldsList = fields
    }
    order() {
        this.fields = this.fields.sort((a, b) => {
            if (parseInt(a.total) > parseInt(b.total)) return -1
        })
    }
    paginateFields(items, current_page, per_page_items) {
        let page = current_page || 1,
            per_page = per_page_items || 10,
            offset = (page - 1) * per_page,
            paginatedItems = items.slice(offset).slice(0, per_page_items),
            total_pages = Math.ceil(items.length / per_page);

        return {
            page: page,
            per_page: per_page,
            pre_page: page - 1 ? page - 1 : null,
            next_page: (total_pages > page) ? page + 1 : null,
            total: items.length,
            total_pages: total_pages,
            data: paginatedItems
        };

    }
}

export class GetFields extends Baselist {
    constructor(list) {
        super()
        this.fields = list
        this.order()
        this.createFields(this.fields)
    }
}